import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { Styles } from "./Styles";
import { fetchCurrentPrices } from "store/pricing/currentPrices/actions";
import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import MultiSelectBox from "components/Selects/MultiSelectBox";
import AveragePricingFilterMultiSelectBox from "./AveragePricingFilterMultiSelectBox";
import PricesTable from "./PricesTable";
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import useColorTheme from "../../../../hooks/useColorTheme";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

const multiSelects = [
  {
    name: "listing",
    placeholder: "Filter Products",
    options: [
      // { lable: "Promotion", value: "promotion" },
      { lable: "New Listing", value: "newly" },
      // { lable: "Reseen", value: "re-listed" },
      // { lable: "Not Seen", value: "de-listed" },
      { lable: "Marketplace", value: "marketplace" },
      { lable: "Price Match", value: "price-match" },
      { lable: "Price Lock", value: "price-lock" },
    ],
  },
];

const selects = [
  {
    name: "limit",
    default: "10",
    options: [
      { lable: "10 Per Page", value: "10" },
      { lable: "20 Per Page", value: "20" },
      { lable: "30 Per Page", value: "30" },
      { lable: "40 Per Page", value: "40" },
      { lable: "50 Per Page", value: "50" },
    ],
  },
  {
    name: "type",
    default: "shelfPrice",
    options: [
      { lable: "Shelf Price", value: "shelfPrice" },
      { lable: "Base Price", value: "basePrice" },
      { lable: "Promoted Price", value: "promotedPrice" },
    ],
  },
];

const CurrentPrices = (props) => {
  const { status, fetchCurrentPrices, statusFilters, statusAuth } = props;
  const {
    setRefreshStatus,
    refreshStatus,
    lastFilter,
  } = useFilters();
  const [sortId, setSortId] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);

  const [queryParams, setQueryParams] = useState({});
  const { primaryColor } = useColorTheme();
  const { cachedCurrentPrices: currentPrices } = useCache();
  const fetchRef = useRef();
  const timerRef = useRef();

  const debounce = (fn, delay) => {
    return (...args) => {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => fn(...args), delay);
    }
  };

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      sourceTypeSort: "",
      productName: "",
      productNameOrder: "asc",
      type: "shelfPrice",
      listing: "",
      page: 1,
      limit: 10,
    }));
  }, [lastFilter]);

  useEffect(() => {
    if (statusFilters === STATE_STATUSES.READY && statusAuth === STATE_STATUSES.PENDING && status !== STATE_STATUSES.PENDING) {
      fetchRef.current = false;
    } else if ((statusFilters === STATE_STATUSES.READY && statusAuth === STATE_STATUSES.READY && !fetchRef.current) || refreshStatus === STATE_STATUSES.PENDING) {
      fetchRef.current = true;
      setRefreshStatus(STATE_STATUSES.READY);

      fetchCurrentPrices({
        sourceTypeSort: "",
        productName: "",
        productNameOrder: "asc",
        type: "shelfPrice",
        listing: "",
        page: 1,
        limit: 10,
        ...(Object.keys(queryParams).length && queryParams),
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriod(lastFilter.date),
        product: lastFilter.product,
      });
    }
  }, [fetchCurrentPrices, lastFilter, queryParams, statusFilters, statusAuth, status, refreshStatus, setRefreshStatus]);

  const handleSetQueryParams = useCallback((newParams) => {
    fetchRef.current = false;
    setQueryParams((prevState) => ({
      ...prevState,
      ...newParams,
    }));
  }, [setQueryParams]);

  const debouncedSetQueryParams = useCallback(debounce(handleSetQueryParams, 900), [handleSetQueryParams]);

  const onChangePage = (page) => {
    handleSetQueryParams({ page });
  };

  const handleSearch = (value) => {
    debouncedSetQueryParams({
      productName: value,
      page: 1,
    });
  };

  const setFilterProduct = (param) => {
    handleSetQueryParams({
      listing: param.listing.join("|"),
      page: 1,
    });
  };

  const setTypeAndPage = (param) => {
    if (Object.keys(param)[0] === "limit") {
      handleSetQueryParams({
        limit: param.limit,
        page: 1,
      });
    } else {
      handleSetQueryParams({
        type: param.type,
        page: 1,
      });
    }
  };

  const handleSort = (id) => {
    let sourceTypeSort;
    if (sortId === id) {
      setSortDirection(!sortDirection);
      sourceTypeSort = `${id},${sortDirection ? "asc" : "desc"}`;
    } else {
      setSortDirection(!sortDirection);
      sourceTypeSort = `${id},${sortDirection ? "asc" : "desc"}`;
    }
    setSortId(id);
    handleSetQueryParams({
      sourceTypeSort,
      productNameOrder: "",
    });
  };

  const handleSortByName = () => {
    if (!queryParams.productNameOrder.length) {
      handleSetQueryParams({
        productNameOrder: "asc",
        sourceTypeSort: "",
      });
    } else if (queryParams.productNameOrder === "asc") {
      handleSetQueryParams({
        productNameOrder: "desc",
        sourceTypeSort: "",
      });
    } else {
      handleSetQueryParams({
        productNameOrder: "asc",
        sourceTypeSort: "",
      });
    }
  };

  return (
    <Styles color={primaryColor}>
      {Object.entries(currentPrices).length !== 0 ? (
        <div className="filters-box">
          {selects.map((item, index) => (
            <SelectBox key={index} data={item} disabled={status !== STATE_STATUSES.READY} setSelectValue={setTypeAndPage} />
          ))}
          {multiSelects.map((item, index) => (
            <AveragePricingFilterMultiSelectBox data={item} setSelectValue={setFilterProduct} disabled={status !== STATE_STATUSES.READY} key={index} />
          ))}
        </div>
      ) : null}

      <div className="wrapper-box-relative">
        {Object.entries(currentPrices).length !== 0 ? (
          <>
            <PricesTable
              data={currentPrices}
              timePeriod={queryParams.timePeriod}
              sortId={sortId}
              sortName={queryParams.productNameOrder}
              sortDirection={sortDirection}
              handleSearch={handleSearch}
              handleSort={handleSort}
              handleSortByName={handleSortByName}
            />
            {currentPrices.total > 0 ? (
              <Pagination
                className="pagination-controls"
                onChange={onChangePage}
                current={queryParams.page ? queryParams.page : 1}
                pageSize={queryParams.limit ? queryParams.limit : 10}
                total={currentPrices.total}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              />
            ) : null}
          </>
        ) : null}
        {status === STATE_STATUSES.PENDING && Object.entries(currentPrices).length === 0 ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default connect(
  (state) => ({
    status: state.currentPrices.status,
    statusFilters: state.filters.status,
    statusAuth: state.authorization.status,
  }),
  { fetchCurrentPrices }
)(CurrentPrices);
