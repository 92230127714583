import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import PricingTopTabs from "tabs/PricingTabs/PricingTopTabs/PricingTopTabs";
import PricingBottomTabs from "tabs/PricingTabs/PricingBottomTabs/PricingBottomTabs";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

const Pricing = (props) => {
  const { statusAuth, filtersStatus, prepareLogoutStatus } = props;
  const { mainLastFilter, statusMainFilters, universalFiltersStatus, statusFilters, titleDateStatus } = useFilters();
  const dispatch = useDispatch();

  const [activeTabTop, setActiveTabTop] = useState(1);
  const [activeTabBottom, setActiveTabBottom] = useState(1);

  const getActionTop = useCallback(
    () => ["SET_PRISTINE_PRICE_TREND", "SET_PRISTINE_CHANGE", "SET_PRISTINE_RANKING"][+activeTabTop - 1],
    [activeTabTop]
  );
  const getPristineTop = useCallback(() => (+activeTabTop > 1 ? false : true), [activeTabTop]);

  const getActionBottom = useCallback(
    () => ["SET_PRISTINE_CURRRENT_PRICES", "SET_PRISTINE_RANGE", "SET_PRISTINE_COMPARISON"][activeTabBottom - 1],
    [activeTabBottom]
  );
  const getPristineBottom = useCallback(() => (activeTabBottom > 1 ? false : true), [activeTabBottom]);

  useEffect(() => {
    return () => {
      dispatch({ type: getActionTop(), payload: getPristineTop() });
      dispatch({ type: getActionBottom(), payload: getPristineBottom() });
    };
  }, [dispatch, getActionBottom, getActionTop, getPristineBottom, getPristineTop]);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch({ type: getActionTop(), payload: true });
      dispatch({ type: getActionBottom(), payload: true });
    }
  }, [filtersStatus, dispatch, getActionBottom, getActionTop]);

  console.log("I'm new here hi everyone");

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <div className="main-title-page">Pricing</div>
        <div className="main-tabs-box">
          <PricingTopTabs setActiveTab={setActiveTabTop} />
        </div>
        <div className="main-tabs-box">
          <PricingBottomTabs setActiveTab={setActiveTabBottom} />
        </div>
      </div>
      {statusAuth !== STATE_STATUSES.PENDING &&
      mainLastFilter &&
      statusMainFilters === STATE_STATUSES.READY &&
      universalFiltersStatus === STATE_STATUSES.READY &&
      statusFilters === STATE_STATUSES.READY &&
      titleDateStatus === STATE_STATUSES.READY &&
      prepareLogoutStatus === STATE_STATUSES.INIT ? null : (
        <LoaderBox isOpacity={true} />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    isPristineTrend: state.priceTrend.isPristine,
    isPristineChange: state.priceChange.isPristine,
    isPristineRanking: state.priceRanking.isPristine,

    isPristinePrices: state.currentPrices.isPristine,
    isPristineRange: state.priceRange.isPristine,
    isPristineComparison: state.priceComparison.isPristine,

    filtersStatus: state.filters.status,
    prepareLogoutStatus: state.authorization.prepareLogoutStatus,
    statusAuth: state.authorization.status,
  }),
  null
)(Pricing);
